<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">{{teaName}}</span>
      <div class="row" v-loading="sta.loading">       
        <el-card style="width:100%; margin-bottom: 20px">
          <el-divider content-position="center"><span style="color: #3bb667">荣誉记录</span></el-divider>
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">
            <el-table-column type="index" label="序号" width="50" align="center" />
            <el-table-column prop="NAME" label="荣誉名称" align="center" />
            <el-table-column prop="IMAGES" label="荣誉照片" align="center">
                <template align="center" slot-scope="scope">
                    <el-image v-for="(item, index) in scope.row.IMAGELIST" :key="index"
                        :src="item.Value"
                        style="width: 50px;height: 50px;  margin-top: 8px;margin-right: 5px" fit="cover"
                        :preview-src-list="srcList" />
                    </template>
            </el-table-column>
            <el-table-column label="荣获时间" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.OBTAIN_DT | datetime('YYYY-MM-DD') }}
                    </template>
             </el-table-column>
          </wgrid>
        </el-card>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="sta.show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Wgrid from "@/components/wgrid";
export default {
  components: { Wgrid },
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                    TEACHER_ID: 0
                },
                ls: [],
                total: 0,
                loading: false,
            },
      teaName:'',
      teaId:0
    }
  },
  methods: {
    init(teaId,teaName) {
      this.teaName = teaName;
      this.teaId=teaId;
      this.grid.sea.TEACHER_ID=teaId;
      this.getList();
    },
    loadPage(pi) {
            this.grid.sea.PAGE_INDEX = pi - 1;
            this.getList();
    },
    getList(){
      this.sta = { show: true, loading: false }
      this.model = {};
      let self = this;
      
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/ORG/TeaHonorApi/GetTeacherList",
        data: this.grid.sea,
        completed: function (its, n) {
                    self.grid.ls = its;
                    self.grid.total = n;
                    self.grid.loading = false;
                    self.srcList = its.map(x => x.IMAGELIST.map(y => y.Value)).flat();
                    console.log('srcList')
                    console.log(self.srcList)
        }
      })
    }
  }
}
</script>
