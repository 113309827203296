<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-world"> </i> 通用管理 | 教师荣誉</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <button type="submit" class="el-button el-button--success" @click="exportExcel">
                            <span>导出Excel</span>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">                            
                            <el-date-picker v-model="date.range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
                            <el-input v-model="grid.sea.NAME" placeholder="请输入关键字" style="width: 300px;">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">
                            <el-table-column type="index" label="序号" width="50" align="center" />
                            <el-table-column prop="TEACHER_NAME" label="教师姓名" align="center" />
                            <el-table-column prop="HONOR_COUNT" label="荣誉总数" align="center" />
                            <el-table-column prop="HONOR_TIME_COUNT" label="荣誉数量" align="center" />
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                <el-button type="text" icon="el-icon-document" @click="edit(scope.row.TEACHER_ID,scope.row.TEACHER_NAME)">详情</el-button>
                                </template>
                            </el-table-column>
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div>
        <Edit ref="edit" />
        </div>
    </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
import Edit from "./detail"
import jsFileDownload from 'js-file-download'
export default {
    name: "index",
    components: { Wgrid,Edit },
    data() {
        return {
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                    NAME: ""
                },
                ls: [],
                total: 0,
                loading: false,
            },
            USER: JSON.parse(localStorage.getItem("userInfo")),
            srcList: [],
            date: { range: [] }
        }
    },
    created() {
        this.getList();
    },
    methods: {
        filter() {
            let n = this.date.range.length
            if (n > 0) {
                this.grid.sea.DTS = this.date.range[0]
                this.grid.sea.DTE = this.date.range[1]
            }

            this.grid.sea.PAGE_INDEX = 0;
            this.grid.ls = [];
            this.getList();
        },
        loadPage(pi) {
            this.grid.sea.PAGE_INDEX = pi - 1;
            this.getList();
        },
        getList() {
            let self = this;
            this.grid.loading = true;
            this.whale.remote.getCollection({
                url: "/api/School/ORG/TeaHonorApi/GetTjList",
                data: this.grid.sea,
                completed: function (its, n) {
                    self.grid.ls = its;
                    self.grid.total = n;
                    self.grid.loading = false;
                    self.srcList = its.map(x => x.IMAGELIST.map(y => y.Value)).flat();
                    console.log('srcList')
                    console.log(self.srcList)
                }
            })
        },
        edit(id,name) {
        this.$refs.edit.init(id,name);
        },
        exportExcel() {
            let self = this;
            this.grid.loading = true;
            const formData = new FormData()
            formData.append('NAME', this.grid.sea.NAME || '')
            this.whale.remote.getExport({
                url: "/api/School/ORG/TeaHonorApi/Export",
                data: formData,
                completed: function (its) {
                    jsFileDownload(its, '导出教师荣誉记录.xls')
                    self.grid.loading = false;
                }
            })
        }
    }
}
</script>